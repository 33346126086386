import React, { Component } from 'react';

import './base.css';

import Header from '../components/header'
import Footer from '../components/footer'
import Body from '../components/body'


class Base extends Component {

  constructor(props)
  {
    super(props);


    this.state =
    { 

    }
  }

  componentDidMount()
  { 

  }


 
  render() {
    return (
      <div id="base_template">
        <div className='base_template_bookends'>
          <div id='base_template_header'>
            <Header />
          </div>
        </div>
        <Body />
        <div className='base_template_bookends'>
          <div id='base_template_footer'>
            <Footer />
          </div>
        </div>

      </div>
    );
  }
}

export default Base;
