import React, { Component } from 'react';

import './index.css';



class Footer extends Component {
  render() {
    return (
      <div className="odieworks_footer">
        Im the footer
      </div>
    );
  }
}

export default Footer;
