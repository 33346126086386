import React, { Component } from 'react';

import './index.css';
import facepalm from '../../../resources/images/picard_palm.jpg';



class Body extends Component {
  constructor(props)
  {
    super(props);

 
  }



  componentDidMount() 
  {

  }





  render() {

    return (
      <div className="odieworks_body">
        Im the body 

        <img src={facepalm} alt="Logo" />;
      </div>
    );
  }
}

export default Body;
